/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 104번째 로그에서는 구글 장애, 슬랙 인수 합병, CentOS, 시놀로지 NAS, 10기가 인터넷에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "구글 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/2020%EB%85%84_12%EC%9B%94_14%EC%9D%BC_%EA%B5%AC%EA%B8%80_%EC%84%9C%EB%B2%84_%EC%9E%A5%EC%95%A0"
  }, "2020년 12월 14일 구글 서버 장애 - 위키백과")), "\n"), "\n", React.createElement(_components.h3, null, "슬랙 인수 합병 & 세일즈포스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20201202083305"
  }, "세일즈포스, 슬랙 인수…277억 달러 '빅딜' - ZDNet")), "\n"), "\n", React.createElement(_components.h3, null, "CentOS"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20201209151925"
  }, "RHEL 클론 '센트OS' 사라진다 - ZDNet")), "\n"), "\n", React.createElement(_components.h3, null, "시놀로지 NAS & 10기가 인터넷 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.synology.com/ko-kr/products/DS1621xs+"
  }, "DiskStation DS1621xs+")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.synology.com/ko-kr/products/DS1819+"
  }, "DiskStation DS1819+")), "\n"), "\n", React.createElement(_components.h3, null, "집무실, 재택근무"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.jibmusil.com/"
  }, "집무실 - 집 근처 사무실")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
